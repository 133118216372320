import React from 'react'
import instagram_icon from '../assets/images/Frame 50-1.svg'
import x_icon from '../assets/images/Frame 50-2.svg'
import facebook_icon from '../assets/images/Frame 50.svg'
import LinkedIn_icon from '../assets/images/Frame 52.svg'
function Footer() {
  return (
   <>
    <section className='bg-primary hidden md:block' id='footer'>
        <div className='container md:py-8 md:flex justify-between gap-x-8 '>
            {/* Social icons */}
            <div className='lg:w-1/5'>
            <a href='#home'>
               <h1 className='text-white font-satoshi font-bold md:text-2xl lg:text-3xl'>From Dreams to Dollar</h1>
            </a>
                <div className='flex gap-4 md:pt-5'>
                    <a href='https://www.instagram.com/the_amit_dubey/?igsh=MTk0bncwMXo4eGx0aw%3D%3D' target='_blank' className='cursor-pointer'>
                         <img src={instagram_icon} className='w-8'/>
                    </a>
                    <a href='https://x.com/TheAmit_Dubey'target='_blank' className='cursor-pointer'>
                        <img src={x_icon} className='w-8'/>
                    </a>
                    <a href='https://www.linkedin.com/in/amitdubey1998/' target='_blank' className='cursor-pointer'>
                        <img src={LinkedIn_icon} className='w-8'/>
                    </a>
                </div>
            </div>
            {/* Quick Links */}
            <div className='md:flex md:w-max md:gap-4 lg:gap-12'>
                <div>
                    <p className='text-white font-satoshi font-bold md:text-xl md:pb-4'>Quick Links</p>
                    <ul className='flex flex-col'>
                        <a href='#home' className='text-white font-satoshi font-medium lg:text-lg'>Home</a>
                        <a href='#you-learn' className='text-white font-satoshi font-medium lg:text-lg'>About</a>
                        <a href='#author' className='text-white font-satoshi font-medium lg:text-lg'>Author</a>
                        <a href='#footer' className='text-white font-satoshi font-medium lg:text-lg'>Contact us</a>
                    </ul>
                </div>
                <div>
                    <p className='text-white font-satoshi font-bold md:text-xl md:pb-12 lg:pb-4'>Resources</p>
                    <ul className=''>
                        <a href='https://theamitdubey.medium.com/'target='_blank' className='cursor-pointer'>
                            <li className='text-white font-satoshi font-medium lg:text-lg'>Blogs</li>
                        </a>
                        <li className='text-white font-satoshi font-medium lg:text-lg'>Free Chapter</li>
                        <li className='text-white font-satoshi font-medium lg:text-lg'>FAQ's</li>
                    </ul>
                </div>
                <div>
                    <p className='text-white font-satoshi font-bold md:text-xl md:pb-12 lg:pb-4'>Connect</p>
                    <ul className=''>
                      <a href='https://www.linkedin.com/in/amitdubey1998/' target='_blank' className='cursor-pointer'>
                        <li className='text-white font-satoshi font-medium lg:text-lg'>LinkedIn</li>
                      </a>
                      <a href='https://x.com/TheAmit_Dubey'target='_blank' className='cursor-pointer'>
                        <li className='text-white font-satoshi font-medium lg:text-lg'>Twitter</li>
                      </a>
                      <a href='https://www.instagram.com/the_amit_dubey/?igsh=MTk0bncwMXo4eGx0aw%3D%3D' target='_blank' className='cursor-pointer'>
                        <li className='text-white font-satoshi font-medium lg:text-lg'>Instagram</li>
                      </a>
                    </ul>
                </div>
            </div>
            {/* Newsletter */}
            <div className='w-max flex justify-end'>
                <div className='flex flex-col md:gap-3 lg:gap-4'>
                    <p className='text-white font-satoshi font-bold md:text-xl'>Newsletter</p>
                    <p className='text-white font-satoshi font-medium text-base'>Stay updated with the latest entrepreneurial tips and news.</p>
                    <input className='py-2 rounded-lg pl-4 md:w-60 lg:w-72' placeholder='Please Enter Your Email'/>
                    <button className='text-primary font-satoshi font-bold lg:text-lg bg-[#FBDE21] py-2 px-4 lg:px-8 rounded-lg md:w-36'>Subscribe</button>
                </div>
            </div>
        </div>
    </section>
            {/* For Mobile screen footer */}
     <section>
        <div className='container py-10 bg-primary block md:hidden'>
             <div className='flex justify-between'>
                  <h2 className='text-white font-satoshi font-bold text-2xl'>From Dreams <br/> to Dollar</h2>
                  <div className='flex justify-center gap-4 items-center'>
                  <a href='https://www.instagram.com/the_amit_dubey/?igsh=MTk0bncwMXo4eGx0aw%3D%3D' target='_blank' className='cursor-pointer'>
                         <img src={instagram_icon} className='w-9'/>
                    </a>
                    <a href='https://www.linkedin.com/in/amit-dubey-0a794778/'target='_blank' className='cursor-pointer'>
                        <img src={x_icon} className='w-9'/>
                    </a>
                    <a href='https://www.linkedin.com/in/amitdubey1998/' target='_blank' className='cursor-pointer'>
                        <img src={LinkedIn_icon} className='w-9'/>
                    </a>
                </div>
             </div>
             <div className='flex gap-16'>
                <div>
                    <p className='text-white font-satoshi font-bold text-2xl pt-12 pb-2 '>Quick Links</p>
                    <ul className='flex flex-col gap-2'>
                        <a href='#home' className='text-white font-satoshi font-medium py-1 text-lg'>Home</a>
                        <a href='#you-learn' className='text-white font-satoshi font-medium py-1 text-lg'>About</a>
                        <a href='#author' className='text-white font-satoshi font-medium py-1 text-lg'>Author</a>
                        <a href='#footer' className='text-white font-satoshi font-medium py-1 text-lg'>Contact us</a>
                    </ul>
                </div>
                <div>
                    <p className='text-white font-satoshi font-bold text-2xl pt-12 pb-2'>Resources</p>
                    <ul className=''>
                        <li className='text-white font-satoshi font-normal py-2 text-lg'>Blogs</li>
                        <li className='text-white font-satoshi font-normal py-2 text-lg'>Free Chapter</li>
                        <li className='text-white font-satoshi font-normal py-2 text-lg'>FAQ's</li>
                    </ul>
                </div>
             </div>
             {/* Newsletter */}
                <div className='flex flex-col  gap-3'>
                    <p className='text-white font-satoshi pt-10 font-bold text-2xl'>Newsletter</p>
                    <p className='text-white font-satoshi font-medium text-lg'>Stay updated with the latest entrepreneurial tips and news.</p>
                    <input className='py-2 rounded-lg pl-4 w-72' placeholder='Please Enter Your Email'/>
                    <button className='text-primary font-satoshi font-bold text-lg bg-[#FBDE21] py-2 px-4 lg:px-8 rounded-lg w-44'>Subscribe</button>
                </div>
        </div>
     </section>

    {/* Copyright */}
    <div className='bg-custom'>
         <p className='font-satoshi font-light text-center py-2 text-primary text-base'>© 2024 Amit Dubey. All rights reserved.</p>
    </div>
   </>
  )
}

export default Footer