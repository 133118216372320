import React, { useState } from 'react';
import HamburgerIcon from '../assets/images/open-icon.svg'; 
import CloseIcon from '../assets/images/close-icon.svg';     

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className='bg-custom'>
        <div className='container flex justify-between items-center pt-4 md:pt-3'>
          <p className='font-satoshi font-bold text-[#050505] text-lg'>
            From Dreams to Dollars
          </p>
          <div className='md:hidden'>
            {/* Custom Icons */}
            {isOpen ? (
              <img
                src={CloseIcon}
                alt='Close menu'
                onClick={toggleMenu}
                className='w-8 h-8 cursor-pointer'
              />
            ) : (
              <img
                src={HamburgerIcon}
                alt='Open menu'
                onClick={toggleMenu}
                className='w-8 h-8 cursor-pointer'
              />
            )}
          </div>
          {/* Navigation Links */}
          <div
            className={`absolute top-12 left-0 w-full bg-custom z-10 px-4 md:px-0 md:static md:w-auto md:bg-transparent md:flex md:items-center md:gap-8 transition-all duration-300 ease-in-out ${
              isOpen ? 'block' : 'hidden'
            }`}
          >
            <a href='#home' className='block py-2 font-satoshi font-medium text-[#383838] text-base'>
              Home
            </a>
            <a href='#you-learn' className='block py-2 font-satoshi font-medium text-[#383838] text-base'>
              About
            </a>
            <a href='#author' className='block py-2 font-satoshi font-medium text-[#383838] text-base'>
              Author
            </a>
            <a href='#footer' className='block py-2 font-satoshi font-medium text-[#383838] text-base'>
              Contact us
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
