import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

function Feedback() {
  // References for the reviews and section
  const reviewRef1 = useRef(null);
  const reviewRef2 = useRef(null);
  const sectionRef = useRef(null);
  
  // Use inView to trigger animations repeatedly
  const isInView1 = useInView(reviewRef1, { once: false });
  const isInView2 = useInView(reviewRef2, { once: false });
  const isSectionInView = useInView(sectionRef, { once: false }); // `once: false` ensures it replays every time it's in view

  // Animation for H2 and H3
  const textAnimation = {
    hidden: { opacity: 0, scale: 1, filter: 'blur(10px)', skewX: '0deg', skewY: '0deg', y: '10%' },
    visible: { 
      opacity: 1, 
      scale: 1, 
      filter: 'blur(0px)', 
      skewX: '0deg', 
      skewY: '0deg', 
      y: '0%', 
      transition: { 
        ease: [0.12, 0.23, 0.5, 1], 
        duration: 1, 
        delay: 0.05 
      }
    }
  };

  // Per-character animation for the paragraph
  const perCharacterAnimation = {
    hidden: { opacity: 0, scale: 1, skewX: '0deg', skewY: '0deg', x: '0%', y: '0%', filter: 'blur(0px)' },
    visible: i => ({
      opacity: 1,
      scale: 1,
      x: 0,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 10,
        duration: 0.4,
        bounce: 0,
        delay: i * 0.05, // Delay per character
      },
    }),
  };

  // Function to split text into individual characters
  const splitTextToSpans = (text) => {
    return text.split("").map((char, index) => (
      <motion.span key={index} custom={index} variants={perCharacterAnimation}>
        {char}
      </motion.span>
    ));
  };

  return (
    <>
      <section className='bg-custom'>
        <div className='container'>
          <h1 className='text-primary py-6 md:py-12 text-center font-satoshi font-bold text-2xl md:text-3xl lg:text-4xl'>
            What Readers Are Saying
          </h1>
          {/* First Review */}
          <motion.div
            ref={reviewRef1}
            className='md:w-1/2 bg-white rounded-3xl p-4 lg:p-7 border border-primary lg:mt-6'
            initial={{ opacity: 0.5, scale: 0.5 }}
            animate={isInView1 ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 0.5 }}
          >
            <p className='text-primary font-satoshi font-bold text-base lg:text-xl'>
              "This book transformed my approach to business. A must-read for every aspiring entrepreneur!"
            </p>
            <p className='text-[#333333] font-satoshi font-bold text-lg pt-3 lg:pt-5'>Anshul Bhawsar</p>
            {/* <p className='text-[#333333] font-satoshi font-bold text-base'>CEO, Tech Innovators</p> */}
          </motion.div>
          {/* Second Review */}
          <div className='md:flex justify-end py-8'>
            <motion.div
              ref={reviewRef2}
              className='md:w-1/2 bg-white rounded-3xl p-4 lg:p-7 border border-primary'
              initial={{ opacity: 0.5, scale: 0.5 }}
              animate={isInView2 ? { opacity: 1, scale: 1 } : {}}
              transition={{ duration: 0.5 }}
            >
              <p className='text-primary font-satoshi font-bold text-base lg:text-xl'>
                "Amit's practical advice and real-world examples make this an invaluable resource for anyone looking to start or grow their business."
              </p>
              <p className='text-[#333333] font-satoshi font-bold text-lg pt-3 lg:pt-5'>Himanshu Madhukar</p>
              {/* <p className='text-[#333333] font-satoshi font-bold text-base'>Founder, Green Solutions</p> */}
            </motion.div>
          </div>
        </div>
      </section>

      {/* Ready to Turn Your Dreams into Dollars? */}
      <section className='bg-custom' ref={sectionRef}>
        <div className='container py-10 lg:py-24 flex flex-col lg:gap-4 items-center'>
          <motion.h2 
            className='lg:text-5xl md:text-3xl text-2xl font-satoshi font-normal'
            initial="hidden"
            animate={isSectionInView ? "visible" : "hidden"}
            variants={textAnimation}
          >
            Ready to Turn Your
          </motion.h2>
          <motion.h3
            className='lg:text-6xl md:text-4xl text-3xl text-primary font-satoshi font-bold'
            initial="hidden"
            animate={isSectionInView ? "visible" : "hidden"}
            variants={textAnimation}
            transition={{ delay: 0.1 }} // Adjusted delay for H3
          >
            Dreams into Dollars?
          </motion.h3>
          <motion.p
            className='text-[#2B2B2B] font-satoshi font-medium lg:text-2xl text-lg py-5 lg:py-8'
            initial="hidden"
            animate={isSectionInView ? "visible" : "hidden"}
            variants={perCharacterAnimation}
            transition={{ staggerChildren: 0.05 }} // Staggering effect for per-character animation
          >
            {splitTextToSpans("Get your copy of the book today and start your journey to entrepreneurial success.")}
          </motion.p>
            {/* Amazon Button */}
               <a href="http://www.amazon.com/dp/B0DHHBDZJW" target="_blank" rel="noopener noreferrer">
                  <motion.button
                    className='text-white font-satoshi font-bold lg:text-lg text-base bg-primary px-4 lg:px-6 py-2 rounded-full border'
                    whileHover={{ opacity: 1, scale: 1.1, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
                    transition={{ type: 'spring', stiffness: 200 }}
                  >
                    Buy Now on Amazon
                  </motion.button>
                </a>
        </div> 
           {/* title circle */}
        <div className='pb-48 lg:pb-16 relative flex justify-center'>
            <svg height="180" width="180" viewBox="0 0 200 200" className="animate-spin-slow mb-6 absolute bottom-0 md:right-16"
            >
              <defs>
                <path
                  id="fullCirclePath"
                  d="M 100, 100
                    m -75, 0
                    a 75,75 0 1,1 150,0
                    a 75,75 0 1,1 -150,0"
                />
              </defs>
              <text className='tracking-widest' fontSize="20" fontFamily="Satoshi">
                <textPath href="#fullCirclePath" fill="#2C4B2D" startOffset="0%">
                  FROM DREAMS TO DOLLARS- AMIT DUBEY
                </textPath>
              </text>
            </svg>
        </div> 

      </section>
    </>
  );
}

export default Feedback;
