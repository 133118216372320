import logo from './logo.svg';
import './App.css';
import HeroSection from './components/HeroSection';
import YouLearn from './components/YouLearn';
import Author from './components/Author';
import Feedback from './components/Feedback';
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  return (
    <>
        <Header/>
        <HeroSection/>
        <YouLearn/>
        <Author/>
        <Feedback/>
        <Footer/>
    </>
   
  );
}

export default App;
