import React, { useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import book_image from '../assets/images/Book-img.png';
import big_cion from '../assets/images/big-coin.png';
import small_coin from '../assets/images/small-coin.png';
import ContactForm from './ContactForm';

function HeroSection() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  }
  const coinDivRef = useRef(null);
  const isCoinDivInView = useInView(coinDivRef, { triggerOnce: false });

  const headingRef = useRef(null);
  const isHeadingInView = useInView(headingRef, { triggerOnce: false });

  return (
    <>
      <section className='bg-custom h-screen' id='home'>
        <div className='flex flex-col gap-4 md:gap-0 items-center justify-center md:h-screen w-full py-10 absolute'>
          {/* H1 Scroll & Initial Load Animation */}
          <motion.h1
            ref={headingRef}
            className='text-primary font-satoshi font-bold text-3xl md:text-5xl lg:text-7xl xl:text-[80px]'
            initial={{ opacity: 0, y: 10, scale: 1, filter: 'blur(10px)' }}
            animate={isHeadingInView ? { opacity: 1, y: 0, scale: 1, filter: 'blur(0px)' } : {}}
            transition={{
              duration: 0.9,
              delay: 0.05,
              ease: [0.44, 0, 0.56, 1],
            }}
          >
            From Dreams to Dollars
          </motion.h1>

          {/* H2 Scroll Animation */}
          <motion.h2
            className='text-secondary px-2 font-satoshi font-medium text-center text-lg lg:text-2xl lg:pt-2'
            initial={{ opacity: 0, scale: 0.5 }}
            animate={isCoinDivInView ? { opacity: 1, scale: 1 } : {}}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ type: 'spring', stiffness: 50, damping: 10 }}
          >
            A Strategic Guide for Aspiring Entrepreneurs to Build Their Future
          </motion.h2>

          {/* Book Image with Scroll Animation */}
          <motion.img
            src={book_image}
            alt="book image"
            className='lg:w-[750px] md:w-[550px] md:pt-8 lg:pt-12'
            initial={{ opacity: 0, y: -150, scale: 1 }}
            animate={isCoinDivInView ? { opacity: 1, y: 0, scale: 1 } : {}}
            transition={{ type: 'spring', stiffness: 70, damping: 15 }}
          />

          {/* Small Coin Image Animation */}
          <motion.img
            src={small_coin}
            alt='small-dollar-coin'
            className='relative lg:-right-80 -right-32 md:-right-72'
            initial={{ opacity: 0, y: -150, scale: 1 }}
            animate={isCoinDivInView ? { opacity: 1, y: 0, scale: 1 } : {}}
            transition={{ type: 'spring', stiffness: 70, damping: 15 }}
          />

         {/* Buttons with Hover Effect */}
              <div className='flex gap-2 mt-36 md:mt-0 lg:gap-8'>
                {/* Amazon Button */}
                <a href="http://www.amazon.com/dp/B0DHHBDZJW" target="_blank" rel="noopener noreferrer">
                  <motion.button
                    className='text-white font-satoshi font-bold lg:text-lg text-base bg-primary px-4 lg:px-6 py-2 rounded-full border'
                    whileHover={{ opacity: 1, scale: 1.1, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
                    transition={{ type: 'spring', stiffness: 200 }}
                  >
                    Buy Now on Amazon
                  </motion.button>
                </a>

                {/* Free Chapter Button */}
                
                  <motion.button
                    className='text-primary font-satoshi font-bold lg:text-lg text-base bg-custom border border-primary px-4 lg:px-6 py-2 rounded-full'
                    whileHover={{ opacity: 1, scale: 1.1, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
                    transition={{ type: 'spring', stiffness: 200 }}
                    onClick={openModal}
                  >
                    Get Free Chapter
                  </motion.button>
              
              </div>
        </div>

        {/* Big Coin Images Animation */}
        <motion.div
          ref={coinDivRef}
          className='lg:flex lg:justify-between '
          initial={{ opacity: 0, y: -150, scale: 1 }}
          animate={isCoinDivInView ? { opacity: 1, y: 0, scale: 1 } : {}}
          transition={{ type: 'spring', stiffness: 70, damping: 15 }}
        >
          <motion.img
            src={big_cion}
            alt='big-dollar-coin'
            className='relative lg:top-14 top-96 md:top-4 md:h-16 lg:h-full h-10 lg:left-20'
            initial={{ opacity: 0, y: -150, scale: 1 }}
            animate={isCoinDivInView ? { opacity: 1, y: 0, scale: 1 } : {}}
            transition={{ type: 'spring', stiffness: 70, damping: 15 }}
          />
          <motion.img
            src={big_cion}
            alt='big-dollar-coin'
            className='relative md:h-16 lg:h-full h-10 md:-right-16 -right-80 top-32 md:top-32 lg:top-36 lg:right-16'
            initial={{ opacity: 0, y: -150, scale: 1 }}
            animate={isCoinDivInView ? { opacity: 1, y: 0, scale: 1 } : {}}
            transition={{ type: 'spring', stiffness: 70, damping: 15 }}
          />
        </motion.div>

        {/* Small Coin Images Animation */}
        <motion.div
          ref={coinDivRef}
          className='lg:flex lg:justify-between'
          initial={{ opacity: 0, y: -150, scale: 1 }}
          animate={isCoinDivInView ? { opacity: 1, y: 0, scale: 1 } : {}}
          transition={{ type: 'spring', stiffness: 70, damping: 15 }}
        >
          <motion.img
            src={small_coin}
            alt='small-dollar-coin'
            className='relative top-64 md:top-0 md:left-[680px] lg:top-52 left-24 lg:left-80'
            initial={{ opacity: 0, y: -150, scale: 1 }}
            animate={isCoinDivInView ? { opacity: 1, y: 0, scale: 1 } : {}}
            transition={{ type: 'spring', stiffness: 70, damping: 15 }}
          />
          <motion.img
            src={small_coin}
            alt='small-dollar-coin'
            className='relative lg:top-32 top-20 h-10 md:h-full md:top-44 -right-10 lg:right-1/3'
            initial={{ opacity: 0, y: -150, scale: 1 }}
            animate={isCoinDivInView ? { opacity: 1, y: 0, scale: 1 } : {}}
            transition={{ type: 'spring', stiffness: 70, damping: 15 }}
          />
        </motion.div>
        {isModalOpen && <ContactForm closeModal={closeModal} />}
      </section>
    </>
  );
}

export default HeroSection;
