import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import author_img from '../assets/images/Amit-Sir.png';

function Author() {
  const titleRef = useRef(null);
  const imgRef = useRef(null);
  const textRef = useRef(null);

  const isTitleInView = useInView(titleRef, { once: true }); // Trigger only once
  const isImgInView = useInView(imgRef, { once: true }); // Trigger only once
  const isTextInView = useInView(textRef, { once: true }); // Trigger only once

  // Text animation object for h1
  const h1Animation = {
    initial: {
      opacity: 0,
      scale: 1,
      filter: 'blur(10px)',
      y: 10,
    },
    animate: {
      opacity: isTitleInView ? 1 : 0,
      scale: 1, // Maintain scale
      filter: isTitleInView ? 'blur(0px)' : 'blur(10px)',
      y: isTitleInView ? 0 : 10,
    },
    transition: {
      duration: 0.1, // Adjust duration for h1
      ease: [0.44, 0, 0.56, 1], // Using the specified Bezier curve
    },
  };

  // Text animation object for the paragraph
  const paragraphAnimation = {
    initial: {
      opacity: 0,
      scale: 1,
      filter: 'blur(10px)',
      y: 10,
    },
    animate: {
      opacity: isTextInView ? 1 : 0,
      scale: 1, // Maintain scale
      filter: isTextInView ? 'blur(0px)' : 'blur(10px)',
      y: isTextInView ? 0 : 10,
    },
    transition: {
      type: 'spring',
      duration: 0.1, // Duration for each character
      bounce: 0, // No bounce
      delay: 0.1, // Delay between characters
    },
  };

  return (
    <section className='bg-custom' id='author'>
      <div className='container py-10 md:py-0'>
        <motion.h1
          className='text-primary text-center font-satoshi font-normal text-2xl md:text-3xl lg:text-4xl'
          variants={h1Animation}
          initial="initial"
          animate={isTitleInView ? "animate" : "initial"}
        >
          Meet the Author
        </motion.h1>
        <div className='flex justify-center'>
          <h2 ref={titleRef} className='text-[#333333] text-center font-syne font-semibold py-3 lg:py-6 text-5xl md:text-6xl lg:text-8xl'>
            {Array.from('Amit Dubey').map((char, index) => (
              <motion.span
                key={index}
                variants={h1Animation}
                initial="initial"
                animate={isTitleInView ? "animate" : "initial"}
                transition={{ delay: index * 0.1 }} // Staggering effect
              >
                {char}
              </motion.span>
            ))}
          </h2>
        </div>
        <div className='md:flex items-center md:gap-10 lg:gap-28 py-4 lg:py-8'>
          <motion.img
            ref={imgRef}
            src={author_img}
            alt="amit-sir"
            className='shadow-2xl rounded-3xl md:h-80 lg:h-full'
            initial={{ opacity: 0, scale: 0.5 }} // Ensure initial state
            animate={isImgInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }} // Adjust animate state
            transition={{ duration: 0.5, ease: [0.44, 0, 0.56, 1] }} // Keep original transition
          />
          <div className='py-8 text-center md:text-left'>
            <motion.p
              ref={textRef}
              className='text-[#0A0A0A] font-satoshi font-bold italic text-lg lg:text-xl lg:leading-8 tracking-wide'
            >
              {Array.from('Amit Dubey is a renowned entrepreneur and business strategist with over 20 years of experience in the tech industry. From humble beginnings in a small village in India, Amit has built and led successful companies, including Dwellfox. In "From Dreams to Dollars," Amit shares his journey and the lessons he\'s learned, providing a roadmap for aspiring entrepreneurs to turn their dreams into successful businesses.').map((char, index) => (
                <motion.span
                  key={index}
                  variants={paragraphAnimation}
                  initial="initial"
                  animate={isTextInView ? "animate" : "initial"}
                  transition={{ delay: index * 0.02 }} // Adjust delay for each character
                >
                  {char}
                </motion.span>
              ))}
            </motion.p>
            <a href='https://www.linkedin.com/in/amitdubey1998/' target='_blank'>
              <motion.button
                className='text-white font-satoshi font-bold lg:text-lg text-base mt-6 lg:mt-12 bg-primary px-4 lg:px-6 py-2 rounded-full border'
                whileHover={{ opacity: 1, scale: 1.1, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
                transition={{ type: 'spring', stiffness: 200 }}
              >
                Read Full Bio
              </motion.button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Author;
