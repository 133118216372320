import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import OvercomingFear from '../assets/images/overcoming.svg';
import foundation from '../assets/images/foundation.svg';
import scalling from '../assets/images/scalling.svg';

function YouLearn() {
  // Reference for in-view detection
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);

  const isTextInView1 = useInView(textRef1, { once: false });
  const isTextInView2 = useInView(textRef2, { once: false });
  const isTextInView3 = useInView(textRef3, { once: false });

  return (
    <section className='bg-custom' id='you-learn'>
      <div className='container md:pt-4 md:pb-16'>
        <h1 className='text-primary text-center font-satoshi font-bold text-[28px] md:text-3xl pb-8 lg:pb-0 lg:text-4xl'>
          What You'll Learn
        </h1>
        <div className='lg:py-16 grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-8 lg:gap-20'>
          <div className='flex flex-col items-center'>
            <img src={OvercomingFear} alt="overcoming fear" className="w-auto" />
            <h2 className='text-primary font-satoshi text-lg lg:text-xl pt-2 lg:pt-8 pb-2 font-bold'>
              Overcoming Fear
            </h2>
            <motion.p
              ref={textRef1}
              className='text-[#333333] text-center font-satoshi font-light text-base lg:text-lg'
              initial={{ opacity: 0, filter: 'blur(10px)', y: 40 }} // Initial state with more blur
              animate={isTextInView1 ? { opacity: 1, filter: 'blur(0px)', y: 0 } : {}} // Blur and slide
              transition={{ duration: 0.7, ease: "easeOut" }} // Slower and smoother
            >
              Learn strategies to conquer your fears and take the first step towards entrepreneurship.
            </motion.p>
          </div>

          <div className='flex flex-col items-center'>
            <img src={foundation} alt="building a strong foundation" className="w-auto" />
            <h2 className='text-primary font-satoshi text-center text-lg lg:text-xl pt-2 lg:pt-8 pb-2 font-bold'>
              Building a Strong Foundation
            </h2>
            <motion.p
              ref={textRef2}
              className='text-[#333333] text-center font-satoshi font-light text-base lg:text-lg'
              initial={{ opacity: 0, filter: 'blur(10px)', y: 40 }} // Initial state with more blur
              animate={isTextInView2 ? { opacity: 1, filter: 'blur(0px)', y: 0 } : {}} // Blur and slide
              transition={{ duration: 0.7, ease: "easeOut" }} // Slower and smoother
            >
              Discover the essential elements needed to create a solid business foundation.
            </motion.p>
          </div>

          <div className='flex flex-col items-center'>
            <img src={scalling} alt="scaling your business" className="w-auto" />
            <h2 className='text-primary font-satoshi text-lg lg:text-xl pt-2 lg:pt-8 pb-2 font-bold'>
              Scaling Your Business
            </h2>
            <motion.p
              ref={textRef3}
              className='text-[#333333] text-center font-satoshi font-light text-base lg:text-lg'
              initial={{ opacity: 0, filter: 'blur(10px)', y: 40 }} // Initial state with more blur
              animate={isTextInView3 ? { opacity: 1, filter: 'blur(0px)', y: 0 } : {}} // Blur and slide
              transition={{ duration: 0.7, ease: "easeOut" }} // Slower and smoother
            >
              Explore proven techniques to grow your business and increase your profits.
            </motion.p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default YouLearn;
