import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { toast,  Toaster} from 'react-hot-toast'

function ContactForm({ closeModal }) {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    whatsappNumber: '',
    preferredMethod: 'email',
    referral: false,
    friendEmail: '',
    message: '',
  });

  const [showReferralField, setShowReferralField] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'referral') {
      setShowReferralField(checked);
    }
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    // Full name validation (optional but should not exceed 50 characters)
    if (formData.fullName && formData.fullName.length > 50) {
      newErrors.fullName = 'Full name should not exceed 50 characters.';
      valid = false;
    }

    // Email validation (required)
    if (!formData.email) {
      newErrors.email = 'Email address is required.';
      valid = false;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
      valid = false;
    }

    // WhatsApp number validation (optional but should be numeric and within a reasonable length)
    if (formData.whatsappNumber && (!/^\d+$/.test(formData.whatsappNumber) || formData.whatsappNumber.length < 8 || formData.whatsappNumber.length > 15)) {
      newErrors.whatsappNumber = 'Please enter a valid WhatsApp number (8-15 digits).';
      valid = false;
    }

    // Friend's email validation (only if referral is checked)
    if (showReferralField && formData.friendEmail && !emailRegex.test(formData.friendEmail)) {
      newErrors.friendEmail = "Please enter a valid friend's email address.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  setLoading(true);
    if (validateForm()) {
      // Prepare the template parameters
      const templateParams = {
        fullName: formData.fullName,
        email: formData.email,
        whatsappNumber: formData.whatsappNumber,
        preferredMethod: formData.preferredMethod,
        message: formData.message,
        friendEmail: formData.friendEmail,
      };
  
      emailjs.send('service_ee3a683', 'template_xosafrc', templateParams, 'wSTJ2QIPy6DkNesLy')
        .then((response) => {
          setLoading(true);
          toast.success('Form submitted successfully!')
          console.log('SUCCESS!', response.status, response.text);
          setTimeout(() => {
            closeModal(); // Close the modal after form submission
          }, 2000)
          
        }, (err) => {
          console.log('FAILED...', err);
          toast.error('Failed to submit form. Please try again later.')
          // Optionally, handle the error, e.g., show an error message to the user
        });
    }
  };
  

  return (
    <div className='fixed z-10 inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center'>
      <div className='bg-white px-4 py-4 mx-4 md:px-8 md:py-6 rounded-lg shadow-lg w-full md:w-4/6 lg:w-1/3'>
        <h2 className='lg:text-3xl text-xl text-center font-satoshi font-bold text-primary lg:pb-4 pb-2'>Get Free Chapter</h2>
        <form onSubmit={handleSubmit}>
          <div className='md:mb-3'>
            <label className='block text-base lg:text-lg font-satoshi font-medium'>Full Name</label>
            <input
              type='text'
              name='fullName'
              value={formData.fullName}
              onChange={handleChange}
              className='w-full border p-2 rounded-lg'
            />
            {errors.fullName && <p className='text-red-500 text-sm'>{errors.fullName}</p>}
          </div>

          <div className='md:mb-3 mb-2'>
            <label className='block text-base lg:text-lg font-satoshi font-medium'>Email Address <span className='text-red-500'>*</span></label>
            <input
              type='email'
              name='email'
              required
              value={formData.email}
              onChange={handleChange}
              className='w-full border p-2 rounded-lg'
            />
            {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}
          </div>

          <div className='md:mb-3 mb-2'>
            <label className='block text-base lg:text-lg font-satoshi font-medium'>WhatsApp Number <span className='text-red-500'>*</span></label>
            <input
              type='number'
              name='whatsappNumber'
              required
              value={formData.whatsappNumber}
              onChange={handleChange}
              className='w-full border p-2 rounded-lg'
            />
            {errors.whatsappNumber && <p className='text-red-500 text-sm'>{errors.whatsappNumber}</p>}
          </div>

          <div className='md:mb-3 mb-2'>
            <label className='block text-base lg:text-lg font-satoshi font-medium'>Preferred Delivery Method</label>
            <div className='flex gap-4'>
              <label className='text-base lg:text-lg font-satoshi font-normal'>
                <input
                  type='radio'
                  name='preferredMethod'
                  value='email'
                  checked={formData.preferredMethod === 'email'}
                  onChange={handleChange}
                  className='mr-1 '
                />
                Email
              </label>
              <label className='text-base lg:text-lg font-satoshi font-normal'>
                <input
                  type='radio'
                  name='preferredMethod'
                  value='whatsapp'
                  checked={formData.preferredMethod === 'whatsapp'}
                  onChange={handleChange}
                  className='mr-1 '
                />
                WhatsApp
              </label>
            </div>
          </div>

          <div className='lg:mb-4 md:mb-3 mb-2'>
            <label className='text-sm lg:text-base font-satoshi font-normal'>
              <input
                type='checkbox'
                name='referral'
                checked={formData.referral}
                onChange={handleChange}
                className='mr-1 '
              />
              Refer a friend and get access to an exclusive second chapter
            </label>
          </div>

          {showReferralField && (
            <div className='md:mb-3 mb-2'>
              <label className='block text-base lg:text-lg font-satoshi font-medium'>Friend's Email Address</label>
              <input
                type='email'
                name='friendEmail'
                value={formData.friendEmail}
                onChange={handleChange}
                className='w-full border p-2 rounded-lg'
              />
              {errors.friendEmail && <p className='text-red-500 text-sm'>{errors.friendEmail}</p>}
            </div>
          )}

          <div className='md:mb-3 mb-2'>
            <label className='block text-base lg:text-lg font-satoshi font-medium'>Message (Optional)</label>
            <textarea
              name='message'
              value={formData.message}
              onChange={handleChange}
              className='w-full border p-2 rounded-lg'
            ></textarea>
          </div>

          <input type='submit' className='bg-primary cursor-pointer text-white text-base font-satoshi font-medium py-2 md:px-10 px-5 rounded-3xl'
          value={loading ? "Submitting..." : "Submit"}
          disabled={loading}
          >
          </input>

          <button
            type='button'
            className='ml-4 bg-white font-satoshi font-medium text-base text-primary py-2 md:px-10 px-5 border border-primary rounded-3xl'
            onClick={closeModal}
          >
            Cancel
          </button>
        </form>
        <Toaster/>
      </div>
     
    </div>
  );
}

export default ContactForm;